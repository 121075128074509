// UserAvatarWithStatus.jsx
import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { useLocation } from 'react-router-dom';

import { LinkWithLanguage as Link } from '../component/LinkWithLanguage.jsx';
import CanvasCircle from '../component/CanvasCircle.jsx';
import UserStatusIndicator from '../container/UserStatusIndicator.js';
import UserAvatar from '../container/UserAvatar.js';
import MeAvatar from '../container/MeAvatar.js';
import LongPress from '../component/LongPress.jsx';
import {
  LIVESTREAM_TYPE_LIVE_BADGE,
  ONLINE_TYPE_DOT,
} from '../resource/userStatusIndicatorConstants.js';

import { ButtonId } from '../resource/mixpanel.js';
import { LIVESTREAM_PATH } from '../resource/liveStreamConstants.js';
import { ProfileListType } from '../resource/profileConstants.js';

import LoadingImage from '../../img/loading.png';
import ProfileIcon from '../../img/placeholder-avatar-cube-60.svg';

export const UserAvatarWithStatus = ({
  meId = null,
  id = '',
  username = '',
  latestMessageId = '',
  latestMessageType = 'flix',
  size,
  circleOffset = 4,
  circleLineWidth = 2,
  imageStyle,
  srcSet,
  liveStreamType = LIVESTREAM_TYPE_LIVE_BADGE,
  livestreamSize = 18,
  onlineType = ONLINE_TYPE_DOT,
  shouldShowLiveStream = false,
  shouldShowOnline = false,
  isLiveStreaming = false,
  isOnline = false,
  pathname = 'flix',
  disableLink = false,
  setProfileSelectedImage = () => null,
  fullSizeAvatarImageSource = '',
  openModal = () => null,
  shouldUseModal = false,
  ...restProps
}) => {
  const { search } = useLocation();
  if (!id) {
    return <UserAvatar size={size} imageStyle={imageStyle} />;
  }
  const hasStory = latestMessageType === 'story' && !!latestMessageId;

  const elementId =
    restProps?.['data-element_id'] || ButtonId.All.ButtonCardAvatar;
  const trackingPayload = {
    ...(restProps?.['data-tracking_payload'] || {}),
    'user.id': id,
    'user.username': username,
  };

  const renderAvatarWithStatus = () => (
    <StyledUserAvatarWithStatus
      data-element_id={elementId}
      data-tracking_payload={trackingPayload}
      size={size}
    >
      {isLiveStreaming ? (
        <CanvasCircle
          colorOne="#a445b3"
          colorTwo="#ff0066"
          circleOffset={circleOffset}
          circleLineWidth={circleLineWidth}
        />
      ) : hasStory ? (
        <CanvasCircle
          circleOffset={circleOffset}
          circleLineWidth={circleLineWidth}
        />
      ) : null}
      {meId != null && meId === id ? (
        <MeAvatar size={size} />
      ) : (
        <UserAvatar
          id={id}
          size={size}
          imageStyle={imageStyle}
          srcSet={srcSet}
        />
      )}
      <UserStatusIndicatorWrapper
        circleOffset={isLiveStreaming || hasStory ? circleOffset : 0}
        isOnline={isOnline}
        isLiveStreaming={isLiveStreaming}
        size={size}
      >
        <UserStatusIndicator
          userId={id}
          liveStreamType={liveStreamType}
          livestreamSize={livestreamSize}
          onlineType={onlineType}
          shouldShowLiveStream={shouldShowLiveStream}
          shouldShowOnline={shouldShowOnline}
        />
      </UserStatusIndicatorWrapper>
    </StyledUserAvatarWithStatus>
  );

  if (disableLink) {
    return <>{renderAvatarWithStatus()}</>;
  }

  if (shouldUseModal) {
    const handleAvatarClickWithModal = () => {
      if (isLiveStreaming || hasStory) {
        openModal('UserAvatarModal');
      } else {
        setProfileSelectedImage({
          imageSrc: fullSizeAvatarImageSource,
          loadingSrc: LoadingImage,
          defaultSrc: ProfileIcon,
          isAvatar: true,
          userId: id,
        });
        openModal('ImageCover');
      }
    };

    return (
      <UserAvatarWithModalWrapper onClick={handleAvatarClickWithModal}>
        {renderAvatarWithStatus()}
      </UserAvatarWithModalWrapper>
    );
  }

  const toPath = isLiveStreaming
    ? `/user/${id}/${LIVESTREAM_PATH}`
    : hasStory
      ? {
          // Keep UserAvatar's link search equals to current page's link search
          // to ensure `state` (listPath) transmit to next story message page
          pathname: `/story/${latestMessageId}`,
          search,
        }
      : `/user/${id}${pathname ? `/${pathname}` : ''}`;
  const state =
    !isLiveStreaming && hasStory
      ? { listPath: ['outbox', ProfileListType.STORY, id] }
      : {};

  const handleLongPressUserAvatar = () => {
    setProfileSelectedImage({
      imageSrc: fullSizeAvatarImageSource,
      loadingSrc: LoadingImage,
      defaultSrc: ProfileIcon,
      isAvatar: true,
      userId: id,
    });
    openModal('ImageCover');
  };

  const haloStatus = isLiveStreaming ? 'livestream' : hasStory ? 'story' : '';

  return (
    <LongPress onLongPress={handleLongPressUserAvatar}>
      <Link
        to={toPath}
        state={state}
        data-element_id={elementId}
        data-tracking_payload={{
          ...trackingPayload,
          ...(haloStatus && {
            'halo.status': haloStatus,
          }),
        }}
      >
        {renderAvatarWithStatus()}
      </Link>
    </LongPress>
  );
};

UserAvatarWithStatus.propTypes = {
  meId: PropTypes.string,
  id: PropTypes.string,
  username: PropTypes.string,
  latestMessageId: PropTypes.string,
  latestMessageType: PropTypes.oneOf(['flix', 'story']),
  size: PropTypes.number.isRequired,
  circleOffset: PropTypes.number,
  circleLineWidth: PropTypes.number,
  imageStyle: PropTypes.object,
  srcSet: PropTypes.array,
  liveStreamType: PropTypes.string,
  livestreamSize: PropTypes.number,
  pathname: PropTypes.string,
  onlineType: PropTypes.string,
  shouldShowLiveStream: PropTypes.bool,
  shouldShowOnline: PropTypes.bool,
  isLiveStreaming: PropTypes.bool,
  disableLink: PropTypes.bool,
  isOnline: PropTypes.bool,
  setProfileSelectedImage: PropTypes.func,
  fullSizeAvatarImageSource: PropTypes.string,
  openModal: PropTypes.func,
  shouldUseModal: PropTypes.bool,
};

const StyledUserAvatarWithStatus = styled.div`
  position: relative;
  ${({ size }) => `
  width: ${size}px;
  height: ${size}px;
`}
`;

const UserAvatarWithModalWrapper = styled.div`
  cursor: pointer;
`;

const UserStatusIndicatorWrapper = styled.div`
  ${({ isLiveStreaming, isOnline, circleOffset = 0 }) => {
    if (isLiveStreaming) {
      return css`
        position: absolute;
        left: 50%;
        bottom: 0px;
        transform: translate(-50%, calc(50% + ${circleOffset}px));
      `;
    } else if (isOnline) {
      return css`
        position: absolute;
        bottom: -2px;
        right: 0px;
      `;
    }
  }}
`;

export default UserAvatarWithStatus;
